const parseQuery = (queryString) => {
	const query = {};
	const pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
	pairs.forEach((pairStr) => {
		const pair = pairStr.split("=");
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
	});
	return query;
};
export default parseQuery;
