<template>
	<div id="app">
		<app-layout>
			<NotificationManager />
			<template #mainmenu>
				<smart-menu
					ref="menu"
					data-cy="main-menu"
					:items="mainMenuData"
					mini
					style="background-color: black"
					title="Tenant Management"
					@menu-item-clicked="handleClick"
				/>
			</template>
			<template #header>
				<MainMenuBreadcrumb
					data-cy="app-header-title"
					:history="route"
					style="--main-menu-breadcrumb-height: 50px"
				/>
			</template>
			<template #usermenu>
				<abas-user-menu
					:name="name"
					:email="email"
					:image="picture"
					:items="dropdownMenuItems"
					data-cy="user-menu"
					style="background-color: black"
					@menu-item-clicked="handleClick"
				/>
			</template>
			<template #buttongroup />
			<template #toolbar>
				<router-view
					name="toolbar"
				/>
			</template>
			<div class="mainPageInput">
				<router-view />
			</div>
		</app-layout>
	</div>
</template>

<script>
import { isEqual } from "lodash";
import {
	AppLayout,
	SmartMenu,
	MainMenuBreadcrumb
} from "@abas/elements";

import AbasUserMenu from "@/AbasUserMenu";
import NotificationManager from "./NotificationManager.vue";
import STORE_CONSTANTS from "./store/constants";

import STAGES from "./stages.json";

export default {
	name: "App",
	components: {
		AbasUserMenu,
		AppLayout,
		SmartMenu,
		MainMenuBreadcrumb,
		NotificationManager,
	},
	data() {
		return {
			breadcrumbs: [
				{
					title: "Identity Providers",
				},
			],
			dropdownMenuItems: [
				{
					title: "Logout",
					icon: "signOut",
					route: "/logout",
				},
			],
			mainMenuData: [
				{
					title: "List Tenants",
					description: "",
					icon: "bars",
					route: "/tenants",
					submenu: false,
				},
			],
		};
	},
	computed: {
		user() {
			return this.$auth.user;
		},
		picture() {
			return this.user ? this.user.picture : null;
		},
		name() {
			return this.user ? this.user.name : null;
		},
		email() {
			return this.user ? this.user.email : null;
		},
		partnerId() {
			return this.user ? this.user["http://abas.cloud/partnerId"] : 1
		},
		route() {
			if (this.selectedTenant) {
				return [{
					title: this.selectedTenant.name + ' > ' + this.$route.name
				}];
			}
			return [{ title: this.$route.name }];
		},
		permissions() {
			return this.$store.getters[STORE_CONSTANTS.permissions.getters.getQueryName()] || [];
		},
		selectedTenant() {
			return this.$store.getters[STORE_CONSTANTS.tenants.getters.getSelectedTenantName()];
		},
	},
	watch: {
		permissions: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (
					!isEqual(newVal, oldVal) &&
					this.permissions.includes("manage-group-permissions") &&
					!this.mainMenuData.some((x) => x.title === "Permissions")
				) {
					this.mainMenuData.push({
						title: "Permissions",
						description: "",
						icon: "key",
						route: "/permissions",
						submenu: false,
					});
				}
			},
		},
		$route(to) {
			if (!to.path.match("/tenant/")) {
				this.$store.dispatch(STORE_CONSTANTS.tenants.getters.getSelectedTenantName(), null);
			}
		},
	},
	async created() {
		this.$store.dispatch(STORE_CONSTANTS.mandants.getters.getQueryName(), this.partnerId);
		this.$store.dispatch(STORE_CONSTANTS.permissions.getters.getQueryName());
		this.$store.dispatch(STORE_CONSTANTS.protocols.getters.getQueryName());
		const stages = STAGES.map((item) => {
			return {
				title: `${item.stage} ${item.region}`,
				icon: "mapPin",
				url: `https://${item.manageDomain}/v2/`,
			};
		});

		this.dropdownMenuItems = this.dropdownMenuItems.concat(stages);
	},
	methods: {
		clicked(item) {
			this.item = item;
		},
		handleClick(context) {
			if (context.route) {
				this.$router.push(context.route);
			} else {
				window.open(context.url);
			}
		},
	},
};
</script>

<style lang="scss">
body {
	margin: 0;
	background-color: $backgroundColor;
}

.mainPageInput {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	border: 1px solid $basicsColor;
	padding: 8px;
	background-color: $white;
	overflow: auto;
}
</style>
