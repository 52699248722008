<template>
	<div class="home">
		<Tabs>
			<Tab
				name="General"
				selected
			>
				<div class="flexbox-row gap-24">
					<div class="flexbox-column-8 max-width-650">
						<SectionDivider
							class="divider"
							:title="tenant.name"
						/>
						<div data-cy="tenantURL">
							<AnchorField
								label="URL"
								@click="openLink(tenantLink)"
							>
								{{ tenantLink }}
							</AnchorField>
						</div>
						<div class="flexbox-row flex-align-items-end">
							<TextField
								class="flex-grow"
								:class="{ 'error': editState && !owner_email }"
								label="Owner"
								:model-value="editState ? owner_email : tenant.owner_email"
								:readonly="!editState"
								@update:model-value="onInput($event, 'owner_email')"
							/>
							<TextField
								label="Owner Partner ID"
								:model-value="tenant.owner_partner_id"
								readonly
							/>
						</div>
						<AutoComplete
							v-model="autoCompleteMandant"
							:class="{ 'read-only': !editState }"
							label="Company"
							:items="mandants"
							:readonly="!editState"
							@invalid-entry="onInvalid('reference_number')"
							@item-selected="onInput($event.referenceid, 'reference_number')"
						/>
						<AutoComplete
							v-model="autoCompletePurpose"
							:class="{ 'read-only': !editState }"
							label="Purpose"
							:items="purposes"
							:readonly="!editState"
							@invalid-entry="onInvalid('purpose')"
							@item-selected="onInput($event.value, 'purpose')"
						/>
						<TextArea
							label="Notes"
							maxlength="255"
							:model-value="editState ? notes : tenant.notes"
							:readonly="!editState"
							@update:model-value="onInput($event, 'notes')"
						/>
					</div>
					<div class="flexbox-column-8 max-width-650">
						<!-- Expiration -->
						<SectionDivider
							class="divider"
							title="Expiration information"
						/>
						<TextField
							label="Expires at"
							readonly
							:model-value="$filters.date(tenant.expiry_time) || 'Never'"
						/>
						<SectionDivider class="divider" />
						<TextField
							label="Created at"
							readonly
							:model-value="$filters.date(tenant.creation_time)"
						/>
						<TextField
							label="Created by"
							readonly
							:model-value="tenant.created_by"
						/>
						<TextField
							label="Last updated at"
							readonly
							:model-value="$filters.date(tenant.last_updated_time)"
						/>
						<div v-if="tenant && tenant.deleted">
							<SectionDivider
								class="divider"
								title="Deletion details"
							/>
							<TextField
								label="Deleted by"
								readonly
								:model-value="tenant.deleted_by"
							/>
							<TextField
								label="Deleted at"
								readonly
								:model-value="$filters.date(tenant.deletion_time)"
							/>
						</div>
					</div>
				</div>
			</Tab>
			<Tab name="Cloud Connect">
				<div class="flexbox-column-8">
					<CloudConnectIcon
						v-if="tenant.name"
						:tenant-name="tenant.name"
						:protocol="tenant.cc_protocol"
					/>
					<TextField
						label="Cloud Connect Protocol Version"
						readonly
						:model-value="tenant.cc_protocol"
					/>
					<SectionDivider
						class="divider"
						title="Client Agent"
					/>
					<TextField
						label="Client Agent Version"
						readonly
						:model-value="tenant.client_agent.version"
					/>
					<TextField
						label="Client Agent last connection time"
						readonly
						:model-value="$filters.date(tenant.client_agent.last_seen) || 'unknown'"
					/>
					<template v-if="isHybridTenant">
						<SectionDivider
							class="divider"
							title="Installation"
						/>
						<div>
							<TextField
								class="command"
								readonly
								label="Cloud Connect archive"
								:model-value="cloudConnectLink"
							>
								<template #suffix>
									<InplaceActionButton
										icon="download"
										title="Download"
										@click="openLink(cloudConnectLink)"
									/>
								</template>
							</TextField>
						</div>
						<div class="flexbox-column-8">
							<div>
								<AnchorField
									label="Installation Guide"
									@click="openLink('https://documentation.abas.cloud/en/cloud-connect/index.html#installation-process')"
								>
									https://documentation.abas.cloud/en/cloud-connect/index.html#installation-process
								</AnchorField>
							</div>
							<div>
								Copy command to clipboard, then paste in bash/sh terminal to install. <br>
								Requires Linux or bash/sh terminal.
							</div>
							<div>
								<TextField
									ref="command"
									class="command"
									readonly
									:model-value="getDownloadInstall"
								>
									<template #suffix>
										<InplaceActionButton
											id="copyCommandBtn"
											icon="copy"
											title="Copy"
											@click="copyCommand"
										/>
									</template>
								</TextField>
							</div>
						</div>
						<SectionDivider
							class="divider"
							title="Configuration Token"
						/>
						<div>
							<CreateConfiguration
								v-if="tenant && tenant.location === 'ON_PREMISE' && !tenant.deleted"
								:tenant="tenant"
							/>
						</div>
					</template>
				</div>
			</Tab>
			<Tab name="Authentication">
				<div class="flexbox-column-8">
					<!-- Connection: Active directory LDAP -->
					<div v-if="tenant.connection.strategy === 'ad'">
						<TextField
							label="Connection Type"
							readonly
							model-value="AD/LDAP Connection"
						/>
						<TextField
							label="Connection Name"
							readonly
							:model-value="tenant.connection.name"
						/>
						<div class="flexbox-row flex-align-items-end">
							<div class="flex-grow">
								<TextField
									label="Ticket URL"
									readonly
									:model-value="tenant.connection.ticket_url"
								/>
							</div>
							<IconButton
								icon="download"
								@click="openLink('https://github.com/auth0/ad-ldap-connector/archive/v3.7.3.zip')"
							/>
							<IconButton
								icon="info"
								@click="openLink('https://auth0.com/docs/authenticate/identity-providers/enterprise-identity-providers/active-directory-ldap/ad-ldap-connector')"
							/>
						</div>
					</div>
					<!-- Connection: Active directory Federation Services-->
					<div v-else-if="tenant.connection.strategy === 'adfs'">
						<TextField
							label="Connection Type"
							readonly
							model-value="ADFS"
						/>
						<TextField
							label="Connection Name"
							readonly
							:model-value="tenant.connection.name"
						/>
						<div class="flexbox-row flex-align-items-end">
							<div class="flex-grow">
								<TextField
									label="Ticket URL"
									readonly
									:model-value="tenant.connection.ticket_url"
								/>
							</div>
							<IconButton
								icon="info"
								@click="openLink('https://auth0.com/docs/authenticate/identity-providers/enterprise-identity-providers/adfs')"
							/>
						</div>
					</div>
					<!-- Connection: Auth0 -->
					<div v-else-if="tenant.connection.strategy === 'auth0'">
						<TextField
							label="Connection Type"
							readonly
							model-value="Auth0 Database Connection"
						/>
						<TextField
							label="Connection name"
							readonly
							:model-value="tenant.connection.name"
						/>
					</div>
					<TextArea
						ref="textArea"
						readonly
						style="position: absolute; left: -9999px"
						:model-value="getDownloadInstall"
					/>
				</div>
			</Tab>
		</Tabs>
		<LoadingOverlay
			v-if="showOverlay"
			class="loadingOverlay"
		/>
	</div>
</template>


<script>
import {
	AnchorField,
	AutoComplete,
	IconButton,
	InplaceActionButton,
	SectionDivider,
	Tab,
	Tabs,
	TextArea,
	TextField,
	LoadingOverlay
} from "@abas/elements";
import { isEqual } from "lodash"

import STORE_CONSTANTS from "@/store/constants";
import EDIT_TENANT_CONSTANTS from "./EditTenant.constants";

import { getTenantLink } from "@/utils/tenant";
import { isEmail } from "@/utils/misc";

import EditTenantMixin from "./EditTenant.mixin";
import UpdateTenantMixin from "./UpdateTenant.mixin";

import CloudConnectIcon from "./CloudConnectIcon.vue";
import CreateConfiguration from "./CreateConfiguration.vue";

import axios from "axios";

export default {
	name: "TenantPage",
	components: {
		AnchorField,
		AutoComplete,
		CloudConnectIcon,
		CreateConfiguration,
		IconButton,
		InplaceActionButton,
		LoadingOverlay,
		SectionDivider,
		Tab,
		Tabs,
		TextArea,
		TextField,
	},
	mixins: [ EditTenantMixin, UpdateTenantMixin ],
	data() {
		return {
			autoCompleteMandant: "",
			autoCompletePurpose: "",
			owner_email: "",
			notes: "",
			reference_number: "",
			purpose: "",
			purposes: [{
				value: "TEST",
				title: "Test",
			}, {
				value: "DEMO",
				title: "Demo",
			}, {
				value: "LIVE",
				title: "Live",
			}],
			originalValues: {},
			tenant: {
				connection: {},
				client_agent: {}
			},
			mandants: [],
			loadingTenantData: false
		};
	},
	computed: {
		showOverlay() {
			return this.$store.getters[STORE_CONSTANTS.editTenant.getters.getQuerySaveInProgress()]
				|| this.loadingTenantData;
		},
		editState() {
			return this.$store.getters[STORE_CONSTANTS.editTenant.getters.getQueryEditStateName()];
		},
		cloudConnectLink() {
			return `${this.tenantLink}/cloud-connect.tgz`;
		},
		tenantId() {
			return this.$route.params.id;
		},
		tenantLink() {
			return getTenantLink(this.tenant.name);
		},
		isHybridTenant() {
			return this.tenant.location === "ON_PREMISE";
		},
		getDownloadInstall() {
			return `(wget -qO- '${this.cloudConnectLink}' | tar xfz -) && ./setup.sh`;
		},
	},
	async created() {
		await this.loadTenantData();
		await this.loadMandantsForOwner()

		this.$emitter.on(EDIT_TENANT_CONSTANTS.events.saveTenant, async () => await this.onSaveTenant());
		this.$emitter.on(EDIT_TENANT_CONSTANTS.events.reloadTenant, async () => await this.onReloadTenant());
		this.$emitter.on(EDIT_TENANT_CONSTANTS.events.updateTenant, async () => await this.onUpdateTenant());
	},
	mounted() {
		["reference_number", "notes", "purpose", "owner_email"].forEach((prop) => {
			this.$watch(prop, this.checkTenantDataChange);
		});
	},
	unmounted() {
		this.$emitter.off(EDIT_TENANT_CONSTANTS.events.saveTenant);
		this.$emitter.off(EDIT_TENANT_CONSTANTS.events.reloadTenant);
		this.$emitter.off(EDIT_TENANT_CONSTANTS.events.updateTenant);
	},
	methods: {
		async loadTenantData() {
			if(this.loadingTenantData) return
			try {
				this.loadingTenantData = true
				const tenant = await this.getTenant(this.tenantId);
				this.tenant = Object.assign({}, {
					cc_protocol: 'unknown',
					connection: {},
					client_agent: {
						version: 'unknown'
					},
				}, tenant);
				await this.$store.dispatch(STORE_CONSTANTS.tenants.getters.getSelectedTenantName(), this.tenant);
				this.loadingTenantData = false
			} catch (e) {
				this.$error(`Could not load tenant, ${e.data?.error}`);
			}
			this.preset()
		},
		async loadMandantsForOwner() {
			try {
				const url = `https://customers.prod.eu-central-1.api.eu.abas.cloud/v1/partners/${this.tenant.owner_partner_id}/customers`;
				const { data } = await axios.get(url);
				this.mandants = this.getMandants(data)
			} catch (e) {
				console.error('Loading companies for owner failed')
				console.error(e)
			}
		},
		onInput(input, prop) {
			// Invalid email
			if (prop === "owner_email" && !isEmail(input)) {
				this.onInvalid("owner_email");
				return;
			}
			this[prop] = input;
		},
		onInvalid(prop) {
			this[prop] = this.originalValues[prop];
			this.checkTenantDataChange();
		},
		openLink(url) {
			window.open(url);
		},
		preset() {
			this.autoCompleteMandant = `${this.tenant.company} (${this.tenant.reference_number})`;
			this.autoCompletePurpose = this.purposes.map((x) => x.title)
				.filter((x) => x.toLowerCase() === this.tenant.purpose?.toLowerCase())[0];

			const { reference_number, notes, purpose, owner_email } = this.tenant;
			Object.assign(this.originalValues, { reference_number, notes, purpose, owner_email });
			Object.assign(this, { reference_number, notes, purpose, owner_email });
		},
		getMandants(mandants) {
			return mandants.map(
				x => {
					x.title = `${x.customer} (${x.referenceid})`;
					return x;
				}
			);
		},
		checkTenantDataChange() {
			const { reference_number, notes, purpose, owner_email } = this;
			let hasChange = true;
			if (isEqual(this.originalValues, { reference_number, notes, purpose, owner_email })) {
				hasChange = false
			}
			this.$store.dispatch(STORE_CONSTANTS.editTenant.getters.queryTenantDataChangedName(), hasChange);
		},
		async onSaveTenant() {
			try {
				await this.$store.dispatch(STORE_CONSTANTS.editTenant.getters.getQuerySaveInProgress());
				const { reference_number, notes, purpose, owner_email } = this;
				await this.saveTenant(this.tenant.id, { reference_number, notes, purpose, owner_email });
				this.$info(`Tenant ${this.tenant.name} updated successfully`);
				await this.$store.dispatch(STORE_CONSTANTS.editTenant.getters.getQueryEditStateName());
				await this.loadTenantData();
				this.checkTenantDataChange();
			} catch (e) {
				this.$error(`Could not update tenant ${this.tenant.name}.\n${e.data?.error}`);
			} finally {
				await this.$store.dispatch(STORE_CONSTANTS.editTenant.getters.getQuerySaveInProgress());
			}
		},
		async onReloadTenant() {
			await this.loadTenantData();
		},
		async onUpdateTenant() {
			try {
				await this.$store.dispatch(STORE_CONSTANTS.editTenant.getters.getQuerySaveInProgress());
				await this.updateTenant(this.tenantId);
				this.$info(`Tenant ${this.tenant.name} updated successfully`);
				await this.loadTenantData();
			} catch(e) {
				this.$error(`Could not update tenant ${this.tenant.name}.\n${e.data?.error}`);
			} finally {
				await this.$store.dispatch(STORE_CONSTANTS.editTenant.getters.getQuerySaveInProgress());
			}
		},
		copyCommand() {
			// fallback old solution
			if (!navigator.clipboard) {
				const el = this.$refs.command;
				el.select();
				document.execCommand("copy");
				this.$info("Command copied to clipboard");
				return;
			}

			const text = this.$refs.command?.$refs?.input?.value
			return navigator.clipboard.writeText(text).then(function() {
				this.$info("Command copied to clipboard");
				return true;
			}, function(e) {
				this.$error('Command could not be copied to clipboard')
				console.error(e);
				return false;
			});

		},
	},
};
</script>

<style lang="scss" scoped>


.home {
	box-sizing: border-box;
	border: none;
	font-family: $abas-font;
	font-size: 14px;
}

.flexbox-column-8 {
	display: flex;
	flex-direction: column;
	gap: 8px;
	min-width: 250px;
}
.max-width-650 {
	flex-grow: 1;
	max-width: 650px;
}

.flexbox-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 8px;
}
.gap-24 {
	gap: 24px;
}
.flex-align-items-end {
	align-items: flex-end;
}

.divider {
	padding: 8px 0 0 0;
}

.command {
	--textfield-max-width: 750px;
}
.read-only {
	pointer-events: none;
}

.error {
	border: 1px solid $errorColorDark;
}

.info {
	display: inline-block;
	margin-left: 10px;
	width: 30px;
}

.loadingOverlay {
	position: absolute;
	top: 105px;
	left: 0;
	width: 100%;
	height: calc( 100% - 105px );
}

.flex-grow {
	flex-grow: 1;
	max-width: 650px;
}
</style>
