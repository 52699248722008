const configuration = {
	getConfig: () => window.fetch("/configuration.json")
		.then(response => response.json())
		.then((response) => {
			const [tenant] = window.location.hostname.split(".");
			response.meta.tenant = tenant;
			return response;
		}),
};
export default configuration;

