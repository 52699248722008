// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from "vue";
import "@abas/elements/style.css"
import { loadable } from "@abas/elements";
import VTooltip from "v-tooltip";
import VueObserveVisibility from "vue-observe-visibility";
import Config from "./utils/configuration";
import parseQuery from "./utils/parseQuery";
import App from "./App.vue";
import ErrorApp from "./ErrorApp.vue";
import router from "./router";
import store from "./store";


/** PLUGINS */
import Auth0 from "./plugins/functionalities/WebClientAuth";
import mitt from "mitt";


/**
 * async functions
 *
 * *Return value*
 * A Promise which will be
 * - resolved with the value returned by the async function, or
 * - rejected with an exception thrown from, or uncaught within, the async function.
 */

const login = async (app) => {
	const auth = app.config.globalProperties.$auth;
	if (!auth.isAuthenticated) {
		const authenticated = await auth.handleAuthentication();
		if (authenticated || auth.isAuthenticated) {
			const parsed = parseQuery(window.location.search);
			if (parsed.redirectPath) {
				window.location.href = parsed.redirectPath;
			}
			return;
		} else {
			// trigger login (might redirect to login page)
			const authorized = await auth.login();
			if (!authorized) {
				// throw error to mount ErrorApp
				throw Error("Couldn't log in.");
			}
		}
	}
};

const initializeApp = async () => {
	try {
		const app = createApp(App)

		// global properties
		app.config.globalProperties.$filters = {
			date(dateString) {
				const localDate = new Date(dateString).toLocaleString();
				return localDate === "Invalid Date" ? "" : localDate;
			}
		}
		app.config.globalProperties.$configuration = await Config.getConfig();
		app.config.globalProperties.$emitter = mitt();

		// plugins
		app.use(Auth0, { configuration: app.config.globalProperties.$configuration });
		app.use(store)
		app.use(router)

		// directives
		app.directive('observe-visibility',VueObserveVisibility);
		app.directive('loadable', loadable);
		app.directive('tooltip',VTooltip);

		await login(app);
		app.mount('#app');
	} catch (e) {
		createApp(ErrorApp, {
			errorMessage: e.message
		}).mount('#app');
	}
}

initializeApp();
