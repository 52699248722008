import Auth from "@abas/ui-authenticator";
import axios from "axios";
import router from "@/router";

/**
 * Exporting new Auth to utils so that it can be tested individually
 */
const createAuth = (tenant, client, redirectUri, audience) =>
	new Auth({
		tenant,
		client,
		redirectUri,
		audience,
	});

const AUTH0_TENANT = "abas-federation";
const CLIENT_ID = "G4UCOYHSQiHR6zvWVtoImzHw0EokwcXv"; // "Tenant Management Webui V2" in auth0 tenant abas-federation
const AUDIENCE = "management";
const BASE = import.meta.env.BASE_URL; // from vue.config.js, see https://cli.vuejs.org/guide/mode-and-env.html#environment-variables

const getRedirectUri = (base) => {
	const { pathname, search, hash } = window.location;
	const redirectPath = pathname + search + hash;
	return base + `/?redirectPath=${encodeURIComponent(redirectPath)}`;
};

// This is your plugin object. It can be exported to be used anywhere.
const WebClientAuth = {
	// create an instance of the Auth0 and add to the global properties
	install(app, { configuration }) {
		const redirectUri = getRedirectUri(BASE);
		const auth0 = createAuth(
			AUTH0_TENANT,
			CLIENT_ID,
			redirectUri,
			AUDIENCE
		);

		/* eslint-disable no-param-reassign */
		app.config.globalProperties.$auth = auth0;
		axios.defaults.baseURL = `${configuration.api.mgmt.tenant}/v1`;
		axios.defaults.headers.post["Content-Type"] = "application/json";
		axios.defaults.headers.put["Content-Type"] = "application/json";
		// Request interceptor
		// -> We set the Authorization via interceptor,
		//    so we always use the newst received accessToken since thye are updated silently
		axios.interceptors.request.use(
			function (config) {
				if (auth0.accessToken) {
					config.headers["Authorization"] =
						"Bearer " + auth0.accessToken;
				} else {
					return Promise.reject("AccessToken missing. Please login.");
				}
				return config;
			},
			function (error) {
				return Promise.reject(error);
			}
		);
		// Add a response interceptor for handling 401
		axios.interceptors.response.use(
			(response) => {
				// Any status code that lie within the range of 2xx cause this function to trigger
				// Do something with response data
				return response;
			},
			(error) => {
				/**
				 * Error handling
				 * https://axios-http.com/docs/handling_errors
				 */
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.debug(error.response);
					if (401 === error.response.status) {
						router.push("/logout");
						return Promise.resolve(error.response);
					} else {
						return Promise.reject({
							status: error.response.status,
							httpStatus: error.response.data.httpStatus,
							message: error.response.data.message,
						});
					}
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.debug(error.request);
					return Promise.reject(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.debug(error.message);
					return Promise.reject({ message: error.message });
				}
			}
		);
	},
};

export default WebClientAuth;
